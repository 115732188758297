





























import HasAchievementModuleConfig from "./HasAchievementModuleConfig";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    SelectInput,
    ConfigField,
    SwitchInput
  }
})
export default class HasAchievementModuleConfigView extends Mixins(ModelSetter) {
  @VModel() model!: HasAchievementModuleConfig
}
